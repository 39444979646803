import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ArrowIcon from "../Icons/ArrowIcon"
import TimeBackground from "../../images/creat-time.png"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin:0 -10px 30px -10px;
`
const LeftColumn = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  @media (min-width:768px){
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`
const RightColumn = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  @media (min-width:768px){
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`

const InfoContent = styled.div`
  position: relative;
  padding:30px;
  background: #f4fbff;
  h2 {
    a {
      color: #062c44;
    }
  }
  p {
    margin: 20px 0;
  }
`
const InfoImage = styled.div`
  position: relative;  
`

const ReadMore = styled(Link)`
  color: #ed602b;
  font-size: 16px;
  font-weight: 800;
  & svg {
    margin-left: 10px;
    transform: none !important;
    transition: ease 0.3s;
    width: 25px;
  }
  & :hover {
    cursor: pointer;
    text-decoration: underline;
    & svg {
      margin-left: 20px;
    }
  }
  @media (max-width: 1440px) {
    position: relative;
    transform: rotate(0deg);
  }
`

const CreatTime = styled.div`
  position: relative;
  width: fit-content;
  background: url(${props => props.bg}) top right;
  background-size: cover;
  padding: 6px 15px 6px 10px;
  margin-top: -15px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  z-index: 20;
`

class InfographicList extends React.Component {
  render() {
    const { data } = this.props
    return (
      <div>
        {data.map((item, i) => {
          const temp = item.node.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ")
          const titleUrl = temp.split(" ").join("-").toLowerCase()
          const url = titleUrl.slice(-1) === "-" ? titleUrl.slice(0, -1) : titleUrl;
          return (
            <Grid key={i}>
              <RightColumn>
                <InfoImage><Link to={`/infographics/${url}/`}><Img fluid={item.node.thumbnail.fluid} alt={item.node.title} /></Link></InfoImage>
                <CreatTime bg={TimeBackground}>
                  {item.node.createdAt}
                </CreatTime>
              </RightColumn>
              <LeftColumn>
                <InfoContent>
                  <h2><Link to={`/infographics/${url}/`}>{item.node.title}</Link></h2>
                  <h3>{item.node.category.name}</h3>
                  <p>{item.node.description?.description}</p>
                  <ReadMore to={`/infographics/${url}/`}>Read Full Story… <ArrowIcon fill="#ED602B" /></ReadMore>
                </InfoContent>
              </LeftColumn>
            </Grid>
          )
        })}
      </div>
    )
  }
}

export default InfographicList
